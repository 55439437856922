
import { useState } from "react";
import CardTutoriel from "./CardTutoriel";
import minia from "../../images/miniatures/miniaturevideo.png"

export default function ListTutoriels() {
const [page, setPage] = useState(true);
const [styleButton1, setStyleButton1] = useState("check");
const [styleButton2, setStyleButton2] = useState("unckeck");

const listTextesEtapes= [

    "\nBienvenue sur Formabuilder, cher(e) formateur(rice) !" 
    +"\nSi vous êtes là, c'est sûrement parce que vous voulez créer un dossier pour votre future formation et que vous avez des documents à envoyer à formagora." 
    +"\n"
    +"\nÇa tombe bien ! Formabuilder est là pour vous aider et vous rendre la tâche plus facile en créant vos documents en seulement quelques minutes."
    +"\n"
    +"\nBien sûr, ce n’est pas la partie la plus fun de votre métier de formateur, mais je vous montre tout de suite comment nous allons vous aider grâce à l’IA !"
    +"\n"
    +"\nCommencez par cliquer sur « Générer mes documents »." , 
    "\nVous allez arriver sur la première étape." 
    +"\n"
    +"\nChoisissez ici les documents que vous voulez générer. "
    +"\nSi vous avez besoin de plus de détails sur un document, regardez simplement la vidéo explicative associée. "
    +"\nUne fois que vous avez sélectionné vos documents, cliquez sur « Suivant »."
    +"\n", 
    "\nDans l’étape 2, vous allez remplir un formulaire avec des informations sur votre formation comme le titre, les thèmes et les objectifs de formation. "
    +"\nIl est très important de le compléter au mieux pour que l’intelligence artificielle puisse créer des documents de qualité. "
    +"\nSi vous n’êtes pas sûr(e) de ce qu'il faut écrire, il y a un petit « i » à côté de chaque champ avec des explications. "
    +"\n"
    +"\nEnsuite, cliquez de nouveau sur « Suivant »."
    +"\n",
    "\nMaintenant, vous arrivez à l’étape 3. Vous allez pouvoir ajouter les documents déjà en votre possession sur cette formation. "
    +"\nPeut-être que vous avez commencé un document ou que vous avez un document d'une formation similaire dans le passé. "
    +"\nBref, ajoutez ici tout ce qui peut nous aider à générer vos documents. "
    +"\nPour ajouter ces informations, cliquez sur le bouton « + » à côté du nom de chaque document. Une fenêtre va s'ouvrir et vous n'avez qu'à copier la partie qui nous intéresse, et la coller dans le champ. "
    +"\nCliquez ensuite sur « Valider ». "
    +"\nCette étape est facultative, mais rappelez-vous que plus vous nous donnez d'informations pertinentes, meilleure sera la qualité des documents générés. "
    +"\nNéanmoins, faites attention à ne pas trop en mettre ! Il y a une limite de caractères qui est indiquée pour chaque champ. Mieux vaut privilégier des textes courts et cohérents qui ont un lien avec le sujet."
    +"\n"
    +"\nSi tout est bon, le bouton avec le « + » deviendra vert. Une fois que vous avez terminé, cliquez sur « Découvrir le résultat »."
    +"\n",
    "\nVoilà, vous êtes maintenant sur la page de résultats !" 
    +"\nPlus qu’à descendre un peu pour voir vos documents. Soyez patient(e), cela peut prendre quelques minutes avant qu'ils ne s'affichent." 
    +"\nFélicitation, vous avez obtenu vos documents !"
    +"\n"
    +"\nMaintenant, prenez le temps de les relire et de faire des modifications si nécessaire. L’intelligence artificielle est performante, mais elle ne peut pas remplacer votre expertise ! Vous pouvez directement modifier les textes sur la page, tous les documents sont modifiables."
    +"\n"
    +"\nUne fois que vous avez vérifié vos documents, il ne reste plus qu’à les transmettre à formagora. Pour ça, commencez par cliquer sur « copier le texte ». Ouvrez ensuite une nouvelle page web, connectez-vous à votre compte SmartOF, et allez sur l’onglet produits de formation ou programme de formation. Vous pouvez maintenant coller votre texte dans le champ correspondant. "
    +"\nEt si les résultats ne vous plaisent pas, ou qu’une erreur s’est produite, pas de panique ! Cliquez sur « recommencer » et la plateforme vous générera de nouveaux textes."
    +"\nEt voilà, c'est fini !"
    +"\n"
    +"\nPetites astuces :"
    +"\n"
    +"\n-	En haut de toutes les pages, vous avez une barre de progression qui vous montre où vous en êtes et quelles étapes ont été validées ou non. Si vous voulez modifier quelque chose, vous pouvez cliquer sur les étapes précédentes à tout moment."
    +"\n"
    +"\n"
    +"\n-	Gardez en tête que vos données ne sont pas sauvegardées par la plateforme. Si vous rechargez la page, vous allez perdre votre travail. Donc n’oubliez pas de bien conserver vos documents avant de fermer la page !"
    +"\n"
    +"\n"
    +"\n"
    +"\nJ'espère que ce tutoriel vous aura aidé à mieux comprendre Formabuilder. "
    +"\nMaintenant, je vous laisse tester l'expérience par vous-même ! "
]

const listTextesDocuments = [
    "Les objectifs de formation sont un des éléments obligatoires, dans le cadre de la réalisation d'une convention de formation. Ils sont observés avec attention de la part des OPCO, lors de la décision pour une prise en charge financière de la formation. Dans le cadre de la certification QUALIOPI, ils doivent apparaître sur l'ensemble des documents commerciaux liés à la formation."
    +"\n\nDans l’étape 2, vous allez être amené(e) à renseigner une ébauche de ces objectifs de formation."
    +"\n\nPour les définir, précisez seulement ce que les participants doivent être capables de faire à la fin de la formation et en situation professionnelle."
    +"\n\nVous pouvez définir plusieurs objectifs, ils seront utilisés pour structurer et construire le programme de formation."
    +"\n\nLes objectifs que vous aurez saisis seront enrichis par les différents thèmes que vous aurez complétés, afin de vous proposer un programme complet et une progression cohérente."
    +"\n\nSi vous disposez déjà d'objectifs de formation pour des formations ressemblantes, vous pourrez les copier, et les coller dans le champ dédié aux objectifs de formation à l'étape 3, et notre outil les prendra en compte dans sa proposition."
    +"\n\nLes objectifs proposés seront rédigés à l'aide de la taxonomie de Bloom, base structurée de verbes permettant de respecter les règles de l'art en ingénierie pédagogique."
    +"\n\nN'oubliez pas que ceci n'est qu'un outil, et que la rédaction des objectifs de formation qu'il va réaliser, devra être relu et corrigé pour éviter toute incohérence !",
    "Le programme de formation est l'élément indispensable à joindre à une convention ou un devis, accompagné des objectifs de cette formation et de différents éléments complémentaires. Avec ces documents, votre client pourra solliciter une prise en charge de la part de son OPCO."
    +"\n\nIci, notre outil vous accompagnera dans la rédaction de ce programme, en prenant en compte les éléments que vous avez complété à l'étape 2, c'est à dire : le titre de la formation, les thèmes et sous-thèmes de la formation, ainsi que les objectifs de la formation."
    +"\n\nVous pouvez préciser la durée du programme que vous souhaitez dans le titre."
    +"\n\nFormabuilder vous proposera alors un programme détaillé, construit sur les bases de la taxonomie de Bloom. Il vous restera à le relire, le corriger, le modifier, et le compléter. Toutefois, notre outil devrait vous permettre de dégrossir l'ensemble de sa rédaction, il vous mâchera en quelque sorte le travail."
    +"\n\nSi vous n'êtes pas satisfait du résultat, n'hésitez pas à cliquer sur « recommencer », ou bien à revenir aux étapes précédentes pour tester d'autres données d'entrée. Ajouter des documents que vous possédez, ou que vous avez déjà utilisés pour des programmes similaires peut vous aider à obtenir de meilleurs résultats."
    +"\n\nGardez en tête que vos données ne sont pas sauvegardées par la plateforme. Donc n’oubliez pas de bien conserver vos documents avant de recommencer !"
    +"\n\nBien sûr, pensez à nous transmettre vos retours, résultats, remarques afin que nous fassions évoluer cette plateforme.",
    "Le déroulé pédagogique est un document également obligatoire dans le cadre d'un process QUALIOPI."
    +"\n\nCe document prévoit l'ensemble du déroulement de la formation en reprenant les objectifs pédagogiques ou compétences travaillées, ce que fait le formateur, ce que font les participants, les outils et méthodes pédagogiques utilisés, ainsi qu'un timing détaillé pour chaque étape."
    +"\n\nNotre outil vous proposera sous forme de tableau un déroulé pédagogique, mais ce document est certainement le plus dur à générer. Aussi, vous devrez le vérifier et le compléter avant validation."
    +"\n\nPensez à vérifier que le déroulé pédagogique reprenne bien l'ensemble des compétences recherchées, qu'il détaille bien pour chaque compétence ainsi que les différents moyens et exercices mise en œuvre."
    +"\n\nBien sûr, nos équipes valideront votre déroulé pédagogique pour chaque formation.",
    "Les quiz ont pour objectif de tester le niveau de connaissance des participants, avant et après la formation. Ils sont une obligation dans le cadre des process QUALIOPI, afin de prouver que la formation a entrainé une montée en compétence. "
    +"\n\nLeur rédaction doit donc permettre de tester les connaissances du participant avant la formation, afin de connaître son niveau de départ. Une seconde évaluation aura lieu en fin de formation, elle pourra être faite avec le même questionnaire, ou avec un questionnaire complémentaire. "
    +"\n\nCes 2 questionnaires d'évaluation font le minimum requis en termes d'évaluation. Ils devront être complétés par des évaluations intermédiaires, en cas de formation plus longue. Ils pourront également être complétés par des études de cas, ou des exercices réalisés pendant ou entre les journées de formation. "
    +"\n\nDans tous les cas, nous vous demanderons de corriger ces évaluations, afin de pouvoir donner une évaluation précise du participant.",
    "Nous avons dans le cadre des process QUALIOPI, l'obligation de remettre aux participants en fin de formation, un support de formation reprenant les éléments principaux vus pendant la formation."
    +"\n\nCe support de formation peut être tout ou partie de votre diaporama, il peut être un résumé des points abordés, ou bien encore un mémo des différents outils travaillés pendant la formation."
    +"\n\nEn attendant que les outils d’intelligence artificielle nous permettent de vous proposer les diaporamas, vous trouverez ici un sommaire possible pour votre support, reprenant les points clés de votre formation."
    +"\n\nN'hésitez pas à l'enrichir, à le compléter, et même certainement à le corriger afin de transmettre aux participants les éléments clés de votre expertise.",
    "Formabuider vous fournira également les objectifs pédagogiques à partir des thèmes et des objectifs de formation. Il s'agit des compétences visées, celles que l'on recherche à atteindre lors de la formation. "
    +"\n\nLes objectifs pédagogiques décrivent les capacités que les participants doivent développer au cours de la formation, pour pouvoir atteindre le ou les objectifs de formation."
    +"\n\nLes objectifs pédagogiques seront générés dans le déroulé pédagogique avec les différents moyens mis en œuvre lors de la formation. Ils doivent être détaillés objectif par objectif. Vous les retrouverez donc dans la deuxième colonne de votre déroulé pédagogique. "
    +"\n\nPensez bien à les compléter, et les corriger lorsque vous ferez une révision complète de votre déroulé pédagogique."


]
let displayedPage = page ?
<div className="liste-carte-tuto">
<CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Comment Fonctionne Formabuilder" text="Bienvenue sur Formabuilder ! Regardons ensemble comment nous pouvons vous aider à générer vos documents de formation en quelques minutes…" longText={listTextesEtapes[0]+listTextesEtapes[1]+listTextesEtapes[2]+listTextesEtapes[3]+listTextesEtapes[4]} />
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Etape 1" text="Voici la partie explicatif pour l'étape 1" longText={listTextesEtapes[1]} />
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Etape 2" text="Voici la partie explicatif pour l'étape 2" longText={listTextesEtapes[2]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Etape 3" text="Voici la partie explicatif pour l'étape 3" longText={listTextesEtapes[3]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Etape 4" text="Voici la partie explicatif pour l'étape 4" longText={listTextesEtapes[4]}/>
</div> : 
<div className="liste-carte-tuto">
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Le programme de formation" text="Le programme de formation est l'élément indispensable à joindre à une convention ou un devis. Formabuilder vous proposera un programme détaillé, construit sur les bases de la taxonomie de Bloom…" longText={listTextesDocuments[0]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Les objectifs de formation" text="Les objectifs de formation sont un des éléments obligatoires, dans le cadre de la réalisation d'une convention de formation. Formabuilder vous propose de corriger les votre en utilisant les bases de la taxonomie de Bloom..." longText={listTextesDocuments[1]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Les objectifs pédagogiques" text="Formabuilder vous fournira également les objectifs pédagogiques, à partir des thèmes et des objectifs de formation. Découvrez comment…" longText={listTextesDocuments[2]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Le déroulé pédagogique" text="Le déroulé pédagogique est un document également obligatoire dans le cadre d'un process QUALIOPI. Notre outil vous proposera de le générer sous forme de tableau…" longText={listTextesDocuments[3]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Les quiz de pré et post formation" text="Les quiz ont pour objectif de tester le niveau de connaissance des participants, avant et après la formation. Nous pouvons grâce à ces quiz prouver que la formation a entrainé une monté en compétences…" longText={listTextesDocuments[4]}/>
    <CardTutoriel srcVideo="https://www.youtube.com/embed/8wwsW6oycKg" imgSrc={minia} title="Le support de formation" text="Nous avons dans le cadre des process QUALIOPI, l'obligation de remettre aux participants en fin de formation, un support de formation reprenant les éléments principaux vus pendant la formation. Voici comment formabuilder vous accompagne…" longText={listTextesDocuments[5]}/>
</div>


function handlePage(){
    setPage(!page)
    if(styleButton1==="check"){
        setStyleButton1("uncheck")
        setStyleButton2("check")
    }else {
        setStyleButton2("uncheck")
        setStyleButton1("check")
    }
}
return (
    <div>
        <div className="buttons-div">
            <div className={"button-container "+styleButton1} onClick={handlePage}>
                <button className="button-tutoriel " >Prise en main de l'outil</button>
                <div className="rounded-border"></div>    
            </div>
            <div className={"button-container "+styleButton2} onClick={handlePage}>
                <button className="button-tutoriel " >Documents administratifs</button>
                <div className="rounded-border"></div>
            </div>
        </div>
        {displayedPage}
    </div>
);
}