import CarteBesoin from "../../composants/EtapeForm/PageBesoin/CarteBesoin";
import "../../css/etapeForm.css";
import ProgressionBar from "../../composants/EtapeForm/ProgressionBar"
import flecheEtape from "../../images/design/flecheEtape.svg"
import { useState, useEffect } from "react";

import { Link } from "react-router-dom";


export default function PageBesoin(props) {
 
    const [document, setDocument] =useState(props.data)
    
    function updateDocs(index) {
      
      setDocument((prevDocument) => {
        const updatedDocument = [...prevDocument];
        updatedDocument[index] = !updatedDocument[index];
        props.dataCallBack(updatedDocument)
        return updatedDocument;
      });
      
    }
    function handleEtape(step) {
     
        if (step > 4) {
          props.handleCallback(step);
        }
      }
      function backPage() {
        props.handleCallback(props.etape -1);
       }
    function NextPage()
    {
      window.scrollTo(0, 0);
        props.handleCallback(1);
    }
  return  <div className="page-besoin">
    <header>
    <div className="header-text" >
        <img src={flecheEtape} alt="fleche de design" />
    <h3 ><Link className="link" to="/"><p> Retour à l'accueil</p>  </Link></h3>
    </div>
    <div>
        <ProgressionBar status={["cours","venir","venir","venir"]} handleCallback={handleEtape}/>
    </div>
    </header>
    <div className="titre">
        <h1>
            De quel(s) documents avez vous besoin ?
        </h1>
        <p>
            Sélectionnez le ou les documents(s) que vous souhaitez générer.
        </p>
    </div>
    
    <form>
        <CarteBesoin index="1" data={props.data} description="Le programme doit répertorier tous ce que les participants vont être amenés à voir. Celui-ci doit être structuré." srcVideo="https://www.youtube.com/embed/8wwsW6oycKg"  title="Programme de formation" updateData={props.dataCallBack}/>
        <CarteBesoin index="2" data={props.data}  description="Ces quiz servent à évaluer le niveau des participants avant et après la formation. Ils mesurent l’impact de la formation." srcVideo="https://www.youtube.com/embed/8wwsW6oycKg"  title="Quiz pré et post formation" updateData={props.dataCallBack}/>
        <CarteBesoin index="3" data={props.data}  description="Les objectifs de formation définissent les résultats attendus pour améliorer les compétences des participants." srcVideo="https://www.youtube.com/embed/8wwsW6oycKg"  title="Objectifs de formation" updateData={props.dataCallBack}/>
        <CarteBesoin index="4" data={props.data}  description="Le support de formation se présente souvent sous la forme de diapositives pour illustrer le cours." srcVideo="https://www.youtube.com/embed/8wwsW6oycKg"  title="Support de formation" updateData={props.dataCallBack}/>
        <CarteBesoin index="5" data={props.data}  description="Le déroulement pédagogique est l'ensemble des étapes et actions mises en place pour enseigner de manière organisée et efficace." srcVideo="https://www.youtube.com/embed/8wwsW6oycKg"  title="Déroulé Pédagogique" updateData={props.dataCallBack}/>
    </form>

    <button className="next-button" onClick={NextPage}> Suivant </button>
  </div> ;
}