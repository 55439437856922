
import croix from "../../../images/croix.svg"

export default function EtiquetteThemes(props) {

    function closeButton(){
        
        props.closeButton(props.text)
    }
  
    return <div className="container-etiquette"><img onClick={closeButton} src={croix}/><p>{props.text}</p></div>

}