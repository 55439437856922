
import { useState } from "react";
import croix from "../../images/croix.svg"
export default function VideTextTuto(props) {

    
    const [page, setPage] = useState(true);
const [styleButton1, setStyleButton1] = useState("check");
const [styleButton2, setStyleButton2] = useState("unckeck");

    function handlePage(){
        setPage(!page)
        
        if(styleButton1==="check"){
            setStyleButton1("uncheck")
            setStyleButton2("check")
        }else {
            setStyleButton2("uncheck")
            setStyleButton1("check")
        }
    }
    let content = 

        <div className="video-container">
            <div className="relative-container">
                <div className="container-title">
                    <h2> {props.title}</h2>
                </div>
                <button onClick={props.handleMethod} className="close-button">
                    <img src={croix}/>
                </button>
                <div className="buttons-div-carte">
            <div className={"button-container "+styleButton1} onClick={handlePage}>
                <button className="button-tutoriel " >Vidéo</button>
                <div className="rounded-border"></div>    
            </div>
            <div className={"button-container "+styleButton2} onClick={handlePage}>
                <button className="button-tutoriel " >Texte</button>
                <div className="rounded-border"></div>
            </div>
        </div>
                {page ? 
                <iframe className={"content-card"} width="1280" height="745" src={props.srcVideo} title="Les solutions de formation - formagora" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                : <div className={"content-text"}>{props.text}</div>}
                </div>
        </div>
    

    
  return (
    content
  );
}