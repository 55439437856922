
import "../../css/footer.css"
import logo from "../../images/design/formagora.png"
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from "react";

export default function Footer() {
 
  return (
    <footer>
      <div className="container-info">
        <div>
          <img src={logo}/>
        </div>
        <div className="container-list-footer">
          <ul>
            <li><Link className="linke" to="/EtapeForm">Générer mes documents </Link></li>
            <li><Link className="linke" to="/#solution">Notre solution</Link></li>
            <li><Link className="linke" to="/Tutoriels">Nos tutoriels</Link></li>
            <li><Link className="linke" >Mentions légales</Link></li>
            <li><Link className="linke">Politiques de confidentialité</Link></li>
          </ul>
        </div>
      </div>
      <div className="container-copyright">
        <p>Copyright © 2023 formagora®. Tous droits réservés</p>
      </div>
    </footer>
  );
}