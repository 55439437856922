import { useState } from "react";
import playButton from "../../../images/playButton.svg";
import croix from "../../../images/croix.svg"
export default function CarteBesoin(props) {
  
  const [check, setCheck] = useState(props.data.document[props.index-1]);
  const [display, setDisplay] = useState(true);


  
  function handleCheck() {
    setCheck(!check);
      props.updateData(props.index-1)
  }
  function handleDisplay(e) {
    e.preventDefault();
    setDisplay(!display)
  }
  let content =  display ? 
    <div>
      <button onClick={handleDisplay}>
        <img className="play-icon" src={playButton} alt="Play Button" />
        <p>Voir la vidéo explicative</p>
      </button>
    </div> :
    <div>
      <button>
        <img className="play-icon" src={playButton} alt="Play Button" />
        <p>Voir la vidéo explicative</p>
      </button>
    <div className="video-container">
      <div className="relative-container">
          <div className="container-title">
            <h2> {props.title}</h2>
          </div>
          <button onClick={handleDisplay}><img src={croix}/></button>
          <iframe width="1280" height="745" src={props.srcVideo} title="Les solutions de formation - formagora" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>  
      </div>
   </div> 
   

  const styleDot = check ? "custom-checkbox checked" : "custom-checkbox unchecked";
  const Besoin = check ? "carte-besoin no" : "carte-besoin yes";

  return (
    <div className={Besoin}  onClick={handleCheck}>
      <div className="haut-carte">
        <div className="text-carte">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
        <div className={styleDot} />
      </div>
      {content}
    </div>
  );
}