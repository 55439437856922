import { useState, useEffect } from "react";

import etoiles from "../../images/design/etoilesConnection.svg"
import enveloppe from "../../images/design/envelope.svg"
import "../../css/etapeConnexion.css"
import {testConnection} from "../../lib"

export default function PageConnexion(props) {
const [email, setEmail] = useState()
const [isValidEmail, setIsValidEmail] = useState(false);
const [displayError, setDisplayError] = useState(false);
const [reponse, setReponse] = useState([{email: "", validation: 0}]);
const [cacheEmail, setCacheEmail] = useState("");
const [cacheDate, setCacheDate] = useState("");



    function inputEmail(e) {
        var lowerCase = e.target.value;
        setEmail(lowerCase);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        
        if(emailRegex.test(lowerCase)){
        setIsValidEmail(true);
        
        }else{
          setIsValidEmail(false)
        }
      } 

      useEffect(() => {
        if(reponse[0].validation == 1){
          
          addDataIntoCache('Email','https://localhost:300',reponse[0].email)
          props.getVerified(true);
        }
      }, [reponse]);

      function handleSubmit(e){
        e.preventDefault();
        addDataIntoCache('Email','https://localhost:300',email)
        if(isValidEmail){  
          const Data = {
            email: email,
          };
          testConnection(Data, setReponse)
        }else{
          setDisplayError(true)
        } 
      }

      

      
    
    
      const addDataIntoCache = (cacheName, url, response) => {
        // Convertir notre réponse en réponse JSON complète
        const data = response;
        
        // Ajouter la date de mise en cache à l'objet avant de le mettre en cache
        const cacheDate = new Date().toISOString(); // Obtenir la date actuelle au format ISO (chaîne)
        const dataWithCacheDate = {
          response,
          cacheDate,
           // Ajouter la date de mise en cache à l'objet response
        };
      
        const cacheResponse = new Response(JSON.stringify(dataWithCacheDate), {
          headers: { 'content-type': 'application/json' },
        });
      
        if ('caches' in window) {
          // Ouvrir le cache spécifié et y mettre nos données
          caches.open(cacheName).then((cache) => {
            cache.put(url, cacheResponse);
            //alert('Data Added into cache!');
          });
        }
      };
      
  return (
    <div className="page-connexion">
       <div className="container-content">
        <h1>{"Bienvenue !"}</h1>
        <img className="etoiles" src={etoiles}/>
        <h2 >Entrez votre <span className="bold-text">adresse mail</span> pour commencer à générer vos documents.</h2>
        <div className="container-info"><div className="container-enveloppe"><img src={enveloppe}/></div> <p className="info-message">Celle-ci doit être la même que celle communiquée à formagora.</p></div>
        <form >
            <div><input type="email" className="input-mail" placeholder="louisdupont@gmail.com" onChange={inputEmail}/>
           {displayError ? isValidEmail ? <p className="correct-message-mail">{"Adresse mail valide :)"}</p>:<p className="error-message-mail">{"Adresse mail non valide :("}</p> :<></>} 
            </div>
              <input className="connexion-button" type="submit" value="Accéder à Formabuilder" onClick={handleSubmit}/>
            
        </form>
       </div>
    </div>
  );
}