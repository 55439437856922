import copyPaste from "../../../images/design/copyPaste.svg";
import { useState, useEffect } from "react";

export default function TableauArea(props) {
  const [content, setContent] = useState(props.text);
  const [display, setDisplay] = useState(false);
  const [datas, setDatas] = useState(null); // Initialize datas state with null

  function extractTextAfterFirstPipe(input) {
    const pipeIndex = input.indexOf('|');
    if (pipeIndex !== -1) {
      return input.substring(pipeIndex + 1);
    } else {
      return input;
    }
  }

  useEffect(() => {
    setContent(props.text); 
    parseTable(props.text); // Call parseTable to update datas state
  }, [props.text]);

  // ... (your implementation for groupBySize function)
function parseTable(input) {
    
    if (input !== "chargement de la réponse") {
        console.log("setup datatas")
      console.log(input)
      const modifiedInput = extractTextAfterFirstPipe(input);
      const lines = modifiedInput.trim().split('\n');
      const header = lines[0].split('|').map((item) => item.trim()).filter(Boolean);
      const data = [];

      for (let i = 2; i < lines.length; i++) {
        const values = lines[i].split('|').map((item) => item.trim()).filter(Boolean);
        if (values.length === header.length) {
          const row = {};
          for (let j = 0; j < header.length; j++) {
            row[header[j]] = values[j];
          }
          data.push(row);
        }
      }

      // Update the datas state with the parsed data
      setDatas(groupBySize(data, 7));
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }
  function groupBySize(arr, size) {
    const groupedArray = [];
    for (let i = 0; i < arr.length; i += size) {
      groupedArray.push(arr.slice(i, i + size));
    }
    return groupedArray;
  }



  

  return (
    <div className="resultat-container">
      <h2>{props.titre}</h2>
      <div className="tableau-péda">
        <button onClick={() => {navigator.clipboard.writeText(content)}}>
          <img src={copyPaste} alt="" />
          <p>copier le texte</p>
        </button>
        <div >
            {display ? <table style={{ border: '1px solid black', width: '100%' }}>
            <thead>
              <tr>
                <th>Thématique</th>
                <th>Objectif pédagogique</th>
                <th>Action formateur</th>
                <th>Action apprenants</th>
                <th>Temps</th>
                <th>Méthode</th>
                <th>Technique/outil</th>
              </tr>
            </thead>
            <tbody>
              {datas[0].map((row, index) => (
                <tr key={index}>
                  <td contentEditable="true">{row['Thématique']}</td>
                  <td contentEditable="true">{row['Objectif pédagogique']}</td>
                  <td contentEditable="true">{row['Action formateur']}</td>
                  <td contentEditable="true">{row['Action apprenants']}</td>
                  <td contentEditable="true">{row['Temps']}</td>
                  <td contentEditable="true">{row['Méthode']}</td>
                  <td contentEditable="true">{row['Technique/outil']}</td>
                </tr>
              ))}
            </tbody>
          </table>
          : <div> en attente </div>}
        </div>
      </div>
    </div>
  );
}
