import { useState } from "react";
import "../../css/etapeCont.css"
import ProgressionBar from "../../composants/EtapeForm/ProgressionBar"
import flecheEtape from "../../images/design/flecheEtape.svg"
import { Link, useHref } from "react-router-dom";
import ButtonInfo from "../../composants/EtapeForm/ButtonInfo"
import EtiquetteThemes from "../../composants/EtapeForm/PageCont/EtiquetteThemes";
export default function PageContenu(props) {
  
    const [titre, setTitre]= useState(props.data.titre);
    const [themes, setThemes]= useState("");
    const [titreErrorCss, setTitreErrorCss]= useState("not-triggered");
    const [themesErrorCss, setThemesErrorCss]= useState("not-triggered");
    const [objectifErrorCss, setObjectifErrorCss]= useState("not-triggered");
    
    const [objectifs, setObjectifs]= useState(props.data.objectifs);

function setUpListEtiquettes(){
let content = props.data.themes

  let listContent = content.split("\n");
 
  listContent = listContent.filter(item => item.trim() !== "" ) ;
  return listContent;
}

    const [listEtiquettes, setListEtiquette] = useState(setUpListEtiquettes);
    function NextPage()
    {
        if(titre!="" && listEtiquettes.length>0 && objectifs != ""){
          window.scrollTo(0, 0);
            props.handleCallback(2);
            
        } else {
          if(titre===""){
            setTitreErrorCss("trigger-error")
          }
          if (listEtiquettes.length<=0){
            setThemesErrorCss("trigger-error")
          }
          if(objectifs === ""){
            setObjectifErrorCss("trigger-error")
          }
          //alert("remplissez tous les champs de textes!");
        }
    }

   
    function inputTitre(e) {
        var lowerCase = e.target.value;
        props.getTitre(lowerCase)
        setTitre(lowerCase);
      }
      function inputThemes(e) {
        var lowerCase = e.target.value;
        
        setThemes(lowerCase);
      }
      function inputObjectifs(e) {
        var lowerCase = e.target.value;
        props.getObjectifs(lowerCase)
        setObjectifs(lowerCase);
      }
      
    function handleEtape(step) {
        if (step <1) {
          props.handleCallback(step);
        }
      }
      function backPage() {
        props.handleCallback(props.etape - 1);
       }

       const handleSubmit = event => {
        event.preventDefault();
        if(themes.trim() !== ""){
        setListEtiquette((prevData) => [...prevData, themes]);
        props.getThemes(" \n" + themes + " ")
        
        }
        setThemes("")
       }

     

       function closeButton(text){
        let deleted = false; // Variable de contrôle pour suivre si l'élément a déjà été supprimé
       console.log("texttodelete")
       console.log(text)
        setListEtiquette(prevList => prevList.filter(item => {
          if(item !== text ){
            
          return true
          }
       }));
        props.deleteTheme(text)
       }

       function handleKeyDownThemes(event) {
        if(event.key ==='Enter'){
          handleSubmit(event)
        }
       }
       
  return <div className="page-contenu">
    <header>
      <div className="header-text" >
        <img src={flecheEtape} alt="fleche de design" />
        <h3 onClick={handleEtape}><p className="link" onClick={backPage}> Retour à l'étape {props.etape}</p> </h3>
      </div>
      <div>
        <ProgressionBar status={["termine","cours","venir","venir"]} handleCallback={handleEtape}/>
      </div>
    </header>
    <div className="titre">
      <h1>
          Informations sur votre formation
      </h1>
      <p>
      Remplissez les champs ci-dessous afin que nous puissions créer vos documents.
      </p>
    </div>
    
    <form> 
      <div className="container-top">
        <div>
            <div className="text-info">
              <p>Titre de la formation <span className="text-obligatoire"> {"(obligatoire)"}</span> </p>
              <ButtonInfo infos={<><p>Celui-ci résume de manière concise (en une phrase) le sujet de la formation. Il s’agit de communiquer aux clients et au participants potentiels ce qu’ils peuvent attendre de la formation.</p><p>Exemple pour une formation sur la prospection téléphonique : Maitrisez votre prospection par téléphone</p></> }/>
            </div>
          <textarea   className={`textarea-titre `}  type="text" onChange={inputTitre} placeholder="Maitrisez votre prospection par téléphone" value={titre}/>
          <p className={`${titreErrorCss}`}>Veuillez renseigner le titre de votre formation.</p>
        </div>
          <div>
            <div className="text-info">
              <p>Thèmes de la formation <span className="text-obligatoire"> {"(obligatoire)"}</span></p>
              <ButtonInfo infos={<> <p>Les thèmes de formation sont les sujets principaux ou les domaines spécifiques abordés dans le cadre de la formation. Ils sont issus des objectifs pédagogiques de la formation, les compétences et les connaissances qui seront enseignés aux participants. Ils peuvent être rédigés en partant de la phrase suivante : « Pendant la formation, les stagiaires développeront des capacités à … »</p>
              <p>Pour renseigner un thème, écrivez-le dans le champ, puis cliquez sur « ajouter ».</p>
              <p>Celui-ci s’affichera en bleu sous le champ. Vous pouvez ajouter autant de thèmes que vous le souhaitez mais nous vous conseillons de rester dans une fourchette de 4 à 6 thèmes.</p>
              <p>Exemple pour une formation sur la prospection téléphonique : </p>
              <ul>
                <li>Savoir mettre en place une organisation liée à un plan d’action de prospection.</li>
                <li>Connaître les techniques comportementales liées à un entretien au téléphone.</li>
                <li>Maitriser toutes les étapes d’un entretien de vente par téléphone</li>
                <li>Appréhender les techniques de vente par téléphone.</li>
                <li>Adopter la bonne communication pour séduire un interlocuteur.</li>
                <li>Appréhender les techniques fondamentales de traitement des objections</li>
                </ul>
              <p></p>
              </>

} />

            </div>
            <div className="container-input-themes">
            <textarea onKeyDown={handleKeyDownThemes} className={`textarea-theme`} type="text" onChange={inputThemes} placeholder="Savoir mettre en place une organisation liée à un plan d’action de prospection quelque chose" value={themes}/>
            <input onClick={handleSubmit} type="submit" value="Ajouter"></input>
            </div>
            <p className={`${themesErrorCss}`}>Veuillez renseigner les thèmes de votre formation.</p>
           <div className="container-themes">
           {listEtiquettes.map((text, index) => {
      
    return (
  <EtiquetteThemes closeButton={closeButton} text={text} key={index} />
);
})}
            </div>
          </div>
        </div>
      <div className="container-bottom">
        <div className="text-info">
          <p>Objectifs de la formation <span className="text-obligatoire"> {"(obligatoire)"}</span></p>
          <ButtonInfo infos={<>
    <p>
      Les objectifs de formation sont les résultats spécifiques que l'on souhaite atteindre à la fin d'une session de formation. Ils définissent les compétences, les connaissances ou les comportements que les participants seront en mesure de mettre en œuvre suite à la formation.
    </p>
    <p>
      Ils sont à différencier des objectifs pédagogiques et peuvent être construits en partant de la phrase suivante : « À l’issue de la formation, les participants seront capables de  .. »
    </p>
    <p>
      Si vous n’êtes pas sûrs de ce que vous écrivez pour cette partie, pas de panique. Vous avez la possibilité de faire retravailler vos objectifs de formation en sélectionnant « objectifs de formation » dans l’étape 1. Dans ce cas, l’intelligence artificielle complétera ce que vous avez écrit et y intégrera la Taxonomie de Bloom
      <a href="https://www.profinnovant.com/taxonomie-de-bloom/" target="_blank"> En savoir plus </a>
      qui est un référentiel pour écrire des objectifs cohérents.
    </p>
    <p>
      À noter que ce champ ne peut pas rester vide ! Nous avons tout de même besoin que vous nous transmettiez une ébauche de vos objectifs pour que nous puissions comprendre le contenu de votre formation.
    </p>
    <p>
      Exemple pour une formation sur la prospection téléphonique :
    </p>
    <p>
      A l'issue de la formation et en situation professionnelle, les participants seront capables de :
    </p>
    <ul>
      <li>Prospecter avec impact et de manière structurée</li>
      <li>Obtenir une organisation liée à la prospection</li>
      <li>Promouvoir les services de sa société</li>
      <li>Détecter des projets et savoir positionner des rendez-vous circonstanciés</li>
    </ul>
  </>}/>
          
        </div>
        <textarea type="text"  onChange={inputObjectifs} placeholder={"A l'issue de la formation et en situation professionnelle, les participants seront capables de :"
+"\n\n-Prospecter avec impact et de manière structurée"
+"\n-Obtenir une organisation liée à la prospection"
+"\n-Promouvoir les services de sa société"
+"\n-Détecter des projets et savoir positionner des rendez-vous circonstanciés"} value={objectifs}/>
        <p className={`${objectifErrorCss}`}>Veuillez renseigner les thèmes de votre formation.</p>
      </div>
    </form>

    <button className="next-button" onClick={NextPage}> Suivant </button>
  </div> ;
  }