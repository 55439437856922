import { useState } from "react";
import result4bis from "../../images/design/result4bis.svg"
export default function ProgressionEtape2(props) {

const [status, setStatus] = useState(props.status);

let statusContent;
function setStat(){
    props.handlestatus(props.index-1);
}

function HandleStatus(){
    if(status==="termine"){
        statusContent =  
        <div>
            <div className="progression-colored2 termine">
                <div className="container-img">
                    <img src={props.imgSrc}/>
                </div>
            </div>
            <div className="block-text-etape">
                <h3>Étape {props.index}</h3>
                <h4>{props.text}</h4>
                <div className="champ-termine">
                    <p>Terminé</p>
                </div>
            </div>
        </div>
    }else if (status==="cours"){
        statusContent =  
        <div>
            <div className="progression-colored2 cours">
                <div className="container-img">
                    <img src={result4bis}/>
                </div>
            </div>
            <div className="block-text-etape">
                <h3>Étape {props.index}</h3>
                <h4>{props.text}</h4>
                <div className="champ-cours2">
                    <p>Et Voilà !</p>
                </div>
            </div>
        </div>
    }else {
        statusContent =  
        <div>
            <div className="progression-colored2 venir">
                <div className="container-img">
                    <img src={props.imgSrc}/>
                </div>
            </div>
            <div className="block-text-etape">
                <h3>Étape {props.index}</h3>
                <h4>{props.text}</h4>
                <div className="champ-venir">
                    <p>À venir</p>
                </div>
            </div>
        </div>
    }
}

HandleStatus();

    return <div className="etape4-div" onClick={setStat}>
        {statusContent}
    </div> ;
}