
import { useState } from "react";
import flecheEtape from "../images/design/flecheEtape.svg"
import { Link } from "react-router-dom";
import ListTutoriels from "../composants/pageTutoriels/ListTutoriels"
import "../css/pageTutoriel.css"
export default function PageTutoriel() {

  return (
    <div className="page-tutoriel">
        <header>
    <div className="header-text" >
        <img src={flecheEtape} alt="fleche de design" />
    <h3 ><Link className="link" to="/"><p> Retour à l'accueil</p>  </Link></h3>
    </div>
    </header>
    <div className="titre-accueil">
        <h1>Laissez-vous guider : <br/> <span className="color-text">on vous explique tout !</span></h1>
        </div>
        <div className="text-explicatif">
        <p>formagora vous met à disposition un catalogue de vidéo vous permettant de mieux comprendre la plateforme et les documents de formation afin de vous accompagner au maximum dans vos démarches. De quoi naviguer sur Formabuilder en toute sérénité !</p>
        </div>
        <div className="list-container">
            <ListTutoriels/>
        </div>
        
    </div>
  );
}